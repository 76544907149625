<template>
  <div>
    <v-card>
      <div class="px-5">
        <card-title :is-image="false" icon="mdi-text-box"
          >Parts Management</card-title
        >
      </div>

      <v-card-text>
        <div
          v-if="isLoading && !vehiclePartsResults.results.length"
          class="tw-my-3"
        >
          <v-divider></v-divider>

          <div
            class="
              tw-flex
              tw-flex-col
              tw-items-center
              tw-justify-center
              tw-gap-3
              tw-py-16
            "
          >
            <v-icon x-large>mdi-text-box</v-icon>

            <div class="text-center">
              <p class="c-gray-text mb-0">
                Currently, there are no vehicles parts
              </p>
              <p class="c-gray-text mb-0">
                Add a vehicle part
                <span class="c-blue-text tw-font-semibold"
                  >process using the button at the bottom right</span
                >
              </p>
            </div>
          </div>
        </div>

        <v-card-text v-else>
          <div class="tw-flex tw-flex-row tw-justify-end">
            <div class="tw-w-full sm:tw-w-1/3 md:tw-w-1/4 tw-pb-3">
              <v-text-field
                v-model="vehiclePartsResults.page.search"
                underlined
                append-icon="mdi-magnify"
                label="Search"
                placeholder="Part Name. e.g Windscreen"
                @input="fetchVehicleParts"
              ></v-text-field>
            </div>

            <div class="tw-w-auto tw-pl-3 tw-pb-3">
              <v-select
                v-model="vehiclePartsResults.page.vehiclePartsRequests"
                :items="[
                  { value: 'ALL_VEHICLE_PARTS', text: 'All' },
                  { value: 'ACTIVE', text: 'Active' },
                  { value: 'DELETED', text: 'Inactive' },
                ]"
                label="Filter Parts"
                default-value="ALL"
                @change="fetchVehicleParts"
              ></v-select>
            </div>
          </div>

          <v-data-table
            :headers="headers"
            :items="vehiclePartsResults.results"
            :loading="isLoading"
            :items-per-page="vehiclePartsResults.page.perPage"
            :page.sync="vehiclePartsResults.page.pageNumber"
            :server-items-length="vehiclePartsResults.totalItems"
            @update:page="onPageUpdate"
            @update:items-per-page="onItemsPerPageUpdate"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 20],
              itemsPerPageText: 'Rows per page',
            }"
          >
            <template v-slot:[`item._active`]="{ item }" dense>
              <v-row dense align="end">
                <v-col dense>
                  <v-btn
                    text
                    medium
                    class="blue--text"
                    dense
                    @click="openDialog('isEditing', item.name, item.id)"
                    >Edit</v-btn
                  >
                </v-col>
                <v-col dense align="end">
                  <v-btn
                    class="red--text"
                    dense
                    text
                    v-if="item._active"
                    @click="openDialog('isDeleting', null, item.id)"
                    >Delete</v-btn
                  >
                  <v-btn
                    class="green--text"
                    dense
                    text
                    v-else
                    @click="deleteRestore(false, item.id)"
                    >Activate</v-btn
                  >
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.updated_by`]="{ item }" dense>
              <span v-if="item.updated_by">{{ item.updated_by }}</span>
              <span v-else>Not updated</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card-text>
    </v-card>

    <v-btn
      bottom
      class="secondary"
      dark
      fab
      fixed
      right
      id="createUserModalBtn"
      @click="openDialog('isCreating')"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="dialog.open" v-if="dialog.open" persistent width="400">
      <v-card v-if="dialog.state.isDeleting">
        <v-card-title class="d-flex flex-column align-center">
          <v-icon large color="red">mdi-delete</v-icon>
          <h3 class="text-center">Confirm Part Deletion</h3>
        </v-card-title>

        <v-card-text>
          <p class="grey--text">
            Please note that deleting this part item will only deactivate it,
            not remove it from the database.
          </p>
        </v-card-text>

        <v-row class="px-4 py-3">
          <v-col cols="12" sm="6">
            <v-btn
              class="tw-w-full"
              color="secondary"
              outlined
              @click="closeDialog"
              >Cancel</v-btn
            >
          </v-col>

          <v-col cols="12" sm="6">
            <v-btn
              class="tw-w-full"
              color="secondary"
              @click="deleteRestore(true)"
              >Delete</v-btn
            >
          </v-col>
        </v-row>
      </v-card>

      <v-card v-else>
        <v-card-title>
          <h3 class="tw-w-full tw-text-center">
            {{ dialog.state.isEditing ? 'Edit' : 'Add' }} Vehicle Part
          </h3>
        </v-card-title>

        <v-card-text>
          <v-form v-model="dialog.form.isValid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Part Name"
                    v-model="dialog.form.editingValue"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-btn
                    class="tw-w-full"
                    color="secondary"
                    outlined
                    @click="closeDialog"
                    >Cancel</v-btn
                  >
                </v-col>

                <v-col cols="12" sm="6">
                  <v-btn
                    class="tw-w-full"
                    color="secondary"
                    :loading="dialog.form.isLoading"
                    :disabled="!dialog.form.isValid"
                    @click="submitForm()"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import store from '@/store'
import axios from 'axios'

export default {
  components: {
    CardTitle,
  },
  data: () => ({
    headers: [
      {
        text: 'Part Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Created By',
        align: 'start',
        sortable: false,
        value: 'created_by',
      },
      {
        text: 'Updated By',
        align: 'start',
        sortable: false,
        value: 'updated_by',
      },
      {
        text: 'Action',
        align: 'center',
        sortable: false,
        value: '_active',
      },
    ],
    isLoading: false,
    loggedInUser: '',
    vehiclePartsResults: {
      results: [],
      page: {
        pageNumber: 1,
        perPage: 10,
        vehiclePartsRequests: 'ALL_VEHICLE_PARTS',
        search: null,
      },
      totalItems: 0,
    },
    restoringVehiclePart: false,
    dialog: {
      open: false,
      state: {
        isEditing: false,
        isCreating: false,
        isDeleting: false,
      },
      form: {
        editingValueId: '',
        editingValue: null,
        isLoading: false,
        isValid: false,
        created_by: store.getters['auth/authenticatedUser']['custom:name'],
        updated_by: store.getters['auth/authenticatedUser']['custom:name'],
      },
    },
  }),
  methods: {
    async fetchVehicleParts() {
      this.isLoading = true

      await axios
        .get('/motor-assessment/api/vehicle-parts-paginated', {
          params: {
            pageNumber: this.vehiclePartsResults.page.pageNumber,
            perPage: this.vehiclePartsResults.page.perPage,
            vehiclePartsRequestType:
              this.vehiclePartsResults.page.vehiclePartsRequests,
            search: this.vehiclePartsResults.page.search,
          },
        })
        .then(({ data }) => {
          if (data.success) {
            this.vehiclePartsResults.results = data.data.content
            this.vehiclePartsResults.totalItems = data.data.totalElements
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.message ?? 'Failed to fetch VehicleParts',
            variant: 'error',
          })
        })
        .finally(() => (this.isLoading = false))
    },
    onPageUpdate(newPage) {
      this.vehiclePartsResults.page.pageNumber = newPage
      this.fetchVehicleParts()
      
    },
    onItemsPerPageUpdate(newItemsPerPage) {
      this.vehiclePartsResults.page.perPage = newItemsPerPage
      this.fetchVehicleParts()
    },
    openDialog(state, editingValue, id) {
      switch (state) {
        case 'isEditing':
          this.dialog.form.editingValue = editingValue
          this.dialog.form.editingValueId = id
          this.dialog.state.isEditing = true
          break

        case 'isCreating':
          this.dialog.form.editingValue = editingValue
          this.dialog.state.isCreating = true

          break
        case 'isDeleting':
          this.dialog.state.isDeleting = true
          this.dialog.form.editingValueId = id
          break

        default:
          this.dialog = {
            open: false,
            state: {
              isEditing: false,
              isCreating: false,
              isDeleting: false,
            },
            processes: [],
            form: {
              isLoading: false,
              isValid: false,
              created_by:
                store.getters['auth/authenticatedUser']['custom:name'],
              updated_by:
                store.getters['auth/authenticatedUser']['custom:name'],
            },
          }
          break
      }
      this.dialog.open = true
    },
    closeDialog() {
      this.dialog = {
        open: false,
        state: {
          isEditing: false,
          isCreating: false,
          isDeleting: false,
        },
        processes: [],
        form: {
          isLoading: false,
          isValid: false,
          created_by: store.getters['auth/authenticatedUser']['custom:name'],
          updated_by: store.getters['auth/authenticatedUser']['custom:name'],
        },
      }
    },
    requiredObject(field) {
      return (v) => (v && !!v['processId']) || `${field} is required`
    },
    required(field) {
      return (v) => !!v || `${field} is required`
    },
    async submitForm() {
      this.dialog.form.isLoading = true

      const method = this.dialog.state.isEditing ? 'put' : 'post'

      const url = '/motor-assessment/api/vehicle-parts'

      let payload
      if (this.dialog.state.isCreating) {
        payload = {
          name: this.dialog.form.editingValue?.toUpperCase().trim(),
          createdBy: this.dialog.form.created_by,
        }
      } else {
        payload = {
          id: this.dialog.form.editingValueId,
          name: this.dialog.form.editingValue?.toUpperCase().trim(),
          updatedBy: this.dialog.form.updated_by,
        }
      }

      await axios({
        method,
        url,
        data: payload,
      })
        .then(async ({ data }) => {
          if (data.success) {
            this.$store.commit('SNACKBAR', {
              state: 'success',
              message:
                data.msg ??
                ` Vehicle Part ${
                  this.dialog.state.isEditing || this.dialog.state.isCreating
                    ? 'updated'
                    : 'added'
                } successfully`,
              variant: 'success',
            })
            await this.fetchVehicleParts()
            this.closeDialog()
          } else {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: data.msg,
              variant: 'error',
            })
            this.closeDialog()
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message:
              err.message ??
              `Failed to ${
                this.dialog.isEditing ? 'update' : 'add'
              } Vehicle Part`,
            variant: 'error',
          })
        })
        .finally(() => (this.dialog.form.isLoading = false))
    },
    async deleteRestore(deleting = true, id = this.dialog.form.editingValueId) {
      this.isLoading = true

      const url = deleting
        ? `/motor-assessment/api/vehicle-parts/${id}`
        : `/motor-assessment/api/vehicle-parts/restore/${id}`
      const method = deleting ? 'delete' : 'put'

      await axios({
        method,
        url,
      })
        .then(async ({ data }) => {
          if (data.success) {
            this.$store.commit('SNACKBAR', {
              state: 'success',
              message: data.msg,
              variant: 'success',
            })
          } else {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: 'Vehicle part does not exist',
              variant: 'error',
            })
          }
        })
        .catch((err) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: err.message ?? `Failed to delete vehicle part`,
            variant: 'error',
          })
        })
        .finally(() => (this.dialog.form.isLoading = false))

      await this.fetchVehicleParts()
      this.closeDialog()
    },
  },
  beforeMount() {
    this.fetchVehicleParts()
  },
}
</script>
